import React from 'react'
import styled from 'styled-components'

const Style = styled.div`
  position: relative;

  input {
    visibility: hidden;

    &:checked {
      & + label > span {
        opacity: 0;
        top: 50%;

        :first-child {
          opacity: 1;
          transform: rotate(405deg);
        }

        :last-child {
          opacity: 1;
          transform: rotate(-405deg);
        }
      }
      &~ nav {
        height: calc(100vh - 70px);
        transition-delay: 0s;
      }
    }
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 15px;
    z-index: 5;

    :hover {
      cursor: pointer;
    }
  }

  span {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 50%;
    margin-top: -1px;
    left: 0;
    display: block;
    background: #ffffff;
    transition: .5s;

    :first-child {
      top: 4px;
    }

    :last-child {
      top: 16px;
    }
  }

  nav {
    background-color: #3f3f3f;
    background-image: linear-gradient(to right top,transparent 0,hsla(0,0%,100%,.2));
    position: fixed;
    top: 70px;
    left: 0;
    width: 100vw;
    height: 0;
    z-index: 103;
    transition: height .5s ease-out;
    transition-delay: .2s;
    overflow: hidden;
    color: #ffffff;

    > div {
      padding: 24px;

      > * {
        padding: 24px 0;
      }
    }
  }

  ul {
    font-size: 1.5rem;

    & + ul {
      border-top: solid 1px #787878;
    }

    li a {
      padding: 0 0 7px;
      border-bottom: solid 4px transparent;

      &:hover {
        border-bottom-color: #ffffff;
      }
    }

    li + li {
      margin-top: .8rem;
    }
  }
`

const Burger = ({ children, ...props }) => {

  const input = React.useRef()

  const handleClose = () => {
    input.current.checked = false
  }

  return (
    <Style {...props}>
      <input id={'burger-menu'} type="checkbox" ref={input}/>
      <label htmlFor={'burger-menu'}>
        <span/>
        <span/>
        <span/>
      </label>

      {/* eslint-disable */}
      <nav onMouseUp={handleClose} onTouchEnd={handleClose}>
      {/* eslint-enable */}
        <div>{children}</div>
      </nav>
    </Style>
  )
}

export default Burger
