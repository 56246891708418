import React from 'react'
import { ThemeProvider } from 'styled-components'
import { ScreenClassProvider } from 'react-grid-system'
import defaultTheme from './src/default-theme'
import Layout from './src/components/Layout'
import './src/global.css'

// Wraps the root in a component
export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <ScreenClassProvider>
        {element}
      </ScreenClassProvider>
    </ThemeProvider>
  )
}

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}