// Mapping from AEP CMCC Version to Major Commerce Version
// Sorted ascending by cutoff version
const versionMappings = [
  { cutoff: 2310, version: "v2" },
  { cutoff: 9999, version: "current" }, // Placeholder for the latest version
];

const getMajorCommerceVersion = (aepCmccVersion) => {
  for (const mapping of versionMappings) {
    if (aepCmccVersion <= mapping.cutoff) {
      return mapping.version;
    }
  }
  return "current"; // Default to "current" if no match found
};

module.exports = { getMajorCommerceVersion: getMajorCommerceVersion };
