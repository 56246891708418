import React from 'react'
import PropTypes from 'prop-types'
import styled from "styled-components";
import clsx from "clsx";

const Style = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  column-gap: 10px;

    margin-bottom: 1rem;

  a.active, a.active:active, a.active:hover, a.active:visited {
    position: relative;
    font-weight: 500;
    color: ${props => props.theme.palette?.text?.primary};
    color: ${props => props.theme.palette?.text?.primary};

    & :before {
      display: block;
      position: absolute;
      content: ' ';
      width: 4px;
      height: 18px;
      top: 0;
      left: -25px;
      background-color: currentColor;
    }
  }

  &.pointer {
    cursor: pointer;
  }

  > div:first-child {
    height: 24px;
    width: 24px;
    min-width: 24px;

    display: flex;
    justify-content: center;
    align-items: start;
  }
`;

const Node = ({ children, onClick, active, ...props }) => {
  return (
    <Style onClick={onClick} className={clsx({ active, pointer: onClick !== undefined})} {...props}>
      <div><div /></div>
      <div>{children}</div>
    </Style>
  )
}

Node.propTypes = {
  /** Set to true, to render this navigation item as active */
  active: PropTypes.bool
}

Node.defaultProps = {
  active: false
}

export default Node;
