import React from 'react'
import PropTypes from 'prop-types'
import styled from "styled-components";
import { BookOpen } from "react-feather";
import clsx from "clsx";

const Style = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  text-transform: uppercase;
  margin-bottom: 6px;
  padding-bottom: 12px;
  border-bottom: solid 1px #bcbcbc;
  
  &.pointer {
    cursor: pointer;
  }

  > div:first-child {
    height: 24px;
  }
`;

const Title = ({ icon, children, onClick }) => {
  return (
    <Style onClick={onClick} className={clsx({ pointer: onClick !== undefined})}>
      <div>{icon}</div>
      <div>{children}</div>
    </Style>
  )
}

Title.propTypes = {
  /** Icon */
  icon: PropTypes.node
}

Title.defaultProps = {
  icon: <BookOpen />
}

export default Title;