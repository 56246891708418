const sectionSortOrder = {
  'how-to-guides': [
    'quick-start',
    'studio-fundamentals',
    'managing-content',
    'studio-development',
    'backend-development',
    'frontend-development',
    'monitoring-and-logging',
    'security'
  ],
  'services': [
    'campaigns',
    'event-hub-service',
    'image-transformation',
    'ingest-service',
    'coremedia-tag'
  ],
  'engagement-cloud': [
    'overview',
    'users',
    'configuring-in-queue-position',
    'managing-audios',
    'analytics',
    'managing-campaigns',
    'segmentation',
    'application-reference',
    'release-notes'
  ],
  'engagement-cloud/analytics': [
    'dashboards',
    'managing-listing-panels',
    'managing-aggregation-panels',
    'analytics-reference'
  ],
  'discover':[
    'api',
    'services',
    'components',
  ],
  'discover/api': [
    'unified-api',
    'uapi-typescript-node',
    'headless-server-api',
    'commerce-api',
    'content-hub-api',
    'feedback-hub-api',
    'personalization-server-api',
    'personalization-client-api',
    'ckeditor5-examples',
]

}

export default sectionSortOrder
