import React from 'react'

/**
 * The CoreMedia logo
 *
 * @returns {*}
 * @constructor
 */
const CoreMediaLogo = props => (
  <svg width="60" height="40" viewBox="0 0 60 40" xmlns="http://www.w3.org/2000/svg" {...props} fill={'currentColor'}>
    <g
      id="g10"
      transform="matrix(0.10545801,0,0,0.10545801,-5.1094406,-5.0186258)">
      <path
        className="cls-1"
        d="m 380.55,275.58 c -13.47,-13.47 -33.71,-17.02 -51.26,-9.62 -31.11,13.12 -69.03,5.3 -92.02,-23.46 -22.6,-28.28 -22.6,-69.24 0,-97.52 22.99,-28.76 60.91,-36.58 92.01,-23.45 17.55,7.4 37.79,3.85 51.26,-9.61 L 402.11,90.35 C 342.65,30.89 244.31,33.44 188.18,98 c -47.38,54.49 -47.38,137.01 0,191.51 56.13,64.56 154.47,67.11 213.93,7.65 l -21.57,-21.57 z"
        id="path6"/>
      <path
        className="cls-1"
        d="m 274.48,193.46 c 0,13.36 10.87,24.23 24.23,24.23 13.36,0 24.23,-10.87 24.23,-24.23 0,-13.36 -10.87,-24.23 -24.23,-24.23 -13.36,0 -24.23,10.87 -24.23,24.23 z"
        id="path8"/>
    </g>
    <g
      id="g10"
      transform="matrix(0.10545801,0,0,0.10545801,-5.1094406,-5.0186258)">
      <path
        className="cls-1"
        d="m 380.55,275.58 c -13.47,-13.47 -33.71,-17.02 -51.26,-9.62 -31.11,13.12 -69.03,5.3 -92.02,-23.46 -22.6,-28.28 -22.6,-69.24 0,-97.52 22.99,-28.76 60.91,-36.58 92.01,-23.45 17.55,7.4 37.79,3.85 51.26,-9.61 L 402.11,90.35 C 342.65,30.89 244.31,33.44 188.18,98 c -47.38,54.49 -47.38,137.01 0,191.51 56.13,64.56 154.47,67.11 213.93,7.65 l -21.57,-21.57 z"
        id="path6"/>
      <path
        className="cls-1"
        d="m 274.48,193.46 c 0,13.36 10.87,24.23 24.23,24.23 13.36,0 24.23,-10.87 24.23,-24.23 0,-13.36 -10.87,-24.23 -24.23,-24.23 -13.36,0 -24.23,10.87 -24.23,24.23 z"
        id="path8"/>
    </g>
    <g
      id="g30"
      transform="matrix(0.10545801,0,0,0.10545801,-5.1094406,-5.0186258)">
      <path
        className="cls-1"
        d="M 48.45,418.26 V 393.6 l 9.3,-9.3 h 34.14 v 8.7 h -30.9 l -3.54,3.54 v 18.78 l 3.54,3.54 h 30.9 v 8.7 H 57.75 Z"
        id="path12"/>
      <path
        className="cls-1"
        d="m 104.96,384.3 h 44.57 v 43.26 h -44.57 z m 35.58,34.56 V 393 h -26.58 v 25.86 z"
        id="path14"/>
      <path
        className="cls-1"
        d="m 166.04,384.3 h 35.04 l 8.82,9.18 v 18.12 h -11.94 l 16.08,15.96 h -11.4 L 186.8,411.78 h -11.88 v 15.78 h -8.88 z m 35.22,18.42 v -6.84 l -2.46,-2.58 h -23.88 v 9.42 z"
        id="path16"/>
      <path
        className="cls-1"
        d="m 226.51,384.3 h 42.84 v 8.88 h -33.54 v 8.52 h 25.62 v 8.46 h -25.62 v 8.4 h 33.54 v 9 h -42.84 z"
        id="path18"/>
      <path
        className="cls-1"
        d="m 284.96,384.3 h 48.83 l 9,9.24 v 34.02 h -8.64 V 396.6 l -3.54,-3.42 h -12.3 v 34.38 h -8.7 v -34.38 h -15.96 v 34.38 h -8.7 V 384.3 Z"
        id="path20"/>
      <path
        className="cls-1"
        d="m 359.05,384.3 h 42.84 v 8.88 h -33.54 v 8.52 h 25.62 v 8.46 h -25.62 v 8.4 h 33.54 v 9 h -42.84 z"
        id="path22"/>
      <path
        className="cls-1"
        d="m 416.64,384.3 h 36.96 l 9.3,9.3 v 24.66 l -9.3,9.3 h -36.96 z m 33.72,34.56 3.54,-3.54 V 396.54 L 450.36,393 h -24.72 v 25.86 z"
        id="path24"/>
      <path
        className="cls-1"
        d="m 477.65,384.3 h 9 v 43.26 h -9 z"
        id="path26"/>
      <path
        className="cls-1"
        d="m 503.15,384.3 h 35.1 l 9.3,9.3 v 33.96 h -8.7 v -16.62 h -27 v 16.62 h -8.7 z m 35.7,18 v -6.42 L 536.03,393 h -24.18 v 9.3 z"
        id="path28"/>
    </g>
  </svg>
);

export default CoreMediaLogo;
