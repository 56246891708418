const sortArticles = (left, right) => {
  const leftOrder =
    left.order
    || (left.node.frontmatter && left.node.frontmatter.order)
    || (left.node.pageAttributes && left.node.pageAttributes.order)
    || Number.MAX_SAFE_INTEGER
  const rightOrder =
    right.order
    || (right.node.frontmatter && right.node.frontmatter.order)
    || (right.node.pageAttributes && right.node.pageAttributes.order)
    || Number.MAX_SAFE_INTEGER
  return parseInt(leftOrder) - parseInt(rightOrder)
}

export default sortArticles