const theme = {
  palette: {
    gray: {
      '500': '#EEEEEE'
    },
    text: {
      primary: '#3f3f3f',
      accent: '#672779'
    }
  }
}

export default theme
