const hideZendesk = () => {
  if (window.zE) {
    window.zE('webWidget','setLocale', 'en');
    window.zE('webWidget', 'hide');
    window.zE('webWidget:on', 'close', function() {
      window.zE('webWidget', 'hide');
    })
  } else {
    setTimeout(() => hideZendesk(), 300)
  }
}

export const initialize = () => {
  window.zESettings = {
    webWidget: {
      contactForm: {
        attachments: false,
        subject: true,
        0x977c32c0e92: true,
        title: {'*': 'Register'},
        fields: [
          {id: 'subject', prefill: {'*': 'Register' }, suppress: true}
        ]
      },
      position: {
        horizontal: 'right',
        vertical: 'top'
      },
      launcher: {
        label: {
          'en-US': 'Register',
          'de-de': "Register",
          '*': 'Register'
        }
      },
      color: { theme: '#006cae' }
    }
  };

  hideZendesk()
}
