import React from 'react'
import clsx from 'clsx'

const Visible = ({ xs = false, sm = false, md = false, lg = false, xl = false, children, className, ...props }) => {

  const cn = clsx({
    'd-xs-none': !xs,
    'd-sm-none': !sm,
    'd-md-none': !md,
    'd-lg-none': !lg,
    'd-xl-none': !xl,
  }, className)

  return (
    <div className={cn} {...props}>
      {children}
    </div>
  )

}
export default Visible