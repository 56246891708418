import React from 'react'
import styled from 'styled-components'
import useSearchQuery from '../../../hooks/use-search-query'
import {Search} from 'react-feather'
import {navigate} from "@gatsbyjs/reach-router";

const ROUTE = '/search'

const SearchWrapper = styled.div`

  &.type-full {

    width: 100%;
    margin: 24px 0;
    position: relative;

    input {
      font-family: Simplon Norm Light, Lucida Sans, Lucida Sans Unicode, Lucida Grande, Arial, Helvetica, sans-serif;
      width: 100%;
      padding: 12px 18px 8px 18px;
      font-size: 20px;
      font-weight: 500;
      border-radius: 24px;
      border: solid 1px #f1f1f1;
      color: #3f3f3f;
      box-shadow: 0 0.8px 2.2px rgba(0, 0, 0, 0.02),
      0 2px 5.3px rgba(0, 0, 0, 0.028),
      0 3.8px 10px rgba(0, 0, 0, 0.035),
      0 6.7px 17.9px rgba(0, 0, 0, 0.042),
      0 12.5px 33.4px rgba(0, 0, 0, 0.05),
      0 30px 80px rgba(0, 0, 0, 0.07);

      &::placeholder {
        color: #3f3f3f88;
      }

      &:focus {
        outline: none;
      }
    }

    .icon {
      position: absolute;
      top: 14px;
      right: 22px;
      color: #3f3f3f;
      font-weight: bold;
      font-size: 22px;
      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;
      }
    }

  }

  &.type-home, &.type-header {
    display: flex;
    align-items: center;

    background-color: rgba(255, 255, 255, .6);
    border: solid 1px rgba(0, 0, 0, .2);
    border-radius: 25px;
    padding: 3px 12px;

    width: calc(18rem - 24px * 2);

    input {
      background-color: transparent;
      border: none;
      font-size: 14px;
      width: 100%;
      margin: 3px 0;
      line-height: 100%;

      &:focus {
        outline: none;
      }
    }

    svg {
      color: #3f3f3f;
      width: 16px;
      height: 16px;
      margin-right: 6px
    }
  }

  &.type-home {
    background-color: rgba(255, 255, 255, .8);
    width: 100%;
    max-width: 640px;
    border-radius: 100px;

    input {
      font-size: 16px;
      height: auto;

      &::placeholder {
        font-style: italic;
        color: #672779;
      }
    }

    svg {
      color: #672779;
      width: 24px;
      height: 24px;
    }
  }
`;

const SearchField = ({type = 'full'}) => {

  const query = useSearchQuery('q');
  const [value, set] = React.useState('');

  React.useEffect(() => {
    set(query ? query : '');
  }, [query, set]);

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      navigate(`${process.env.SITE_PATH_PREFIX}${ROUTE}/?q=${e.target.value}&path=${window.location.href.split('?')[0]}`)
    }
  }

  return (
    <SearchWrapper className={`type-${type}`}>
      {type !== 'full' && (
        <Search/>
      )}
      <input type="text" placeholder={`Search documentation …`} value={value} onChange={e => set(e.target.value)}
             onKeyDown={onKeyDown}/>
      {type === 'full' && <div className={'icon'}><Search/></div>}
    </SearchWrapper>
  )
}

export default SearchField;
