import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Node from "../Node";
import { Link } from "gatsby"
import { OVERVIEW_ID_PREFIX } from "../../../hooks/use-navigation";
import AnimatedAccordion from '../../AnimatedAccordion'
import { useMatch } from '@gatsbyjs/reach-router'
import clsx from 'clsx'

const Styled = styled.div`
  padding-left: 26px !important;
  &.active h3:first-of-type {
    font-weight: 500;
  }


`;

const Children = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const Accordion = ({children, label, slug, navigation}) => {

  const active = useMatch(`${process.env.SITE_PATH_PREFIX}/${slug}/*`);
  const areThereSubCategories = navigation.tree.filter((item) => {
    return item.slug.startsWith(slug) && (item.slug !== slug) && (item.slug.split("/").length > 2)
  })

  return (
    <Styled className={clsx({ active })}>
      <AnimatedAccordion title={label} open={active}>
        <Children>
          {children
            ? children.map((item, index) => (
              <Node key={index} onClick={item.onClick}>
                {item.id.startsWith(OVERVIEW_ID_PREFIX) ? (
                  <Link to={`/${item.slug}/`} activeClassName="active">
                    {item.label}
                  </Link>
                ) : (
                  <Link to={`/${item.slug}/`} activeClassName="active" partiallyActive={true}>
                    {item.label}
                  </Link>
                )}
              </Node>
            ))
            : ''}
          {areThereSubCategories.length > 0
            ? areThereSubCategories.map((item, index) => (
              <Accordion key={index} label={item.label} slug={item.slug} navigation={navigation}>
                {item.children}
              </Accordion>
            ))
            : ''}
        </Children>
      </AnimatedAccordion>
    </Styled>
  );
};

Accordion.propTypes = {
  /** Set to true to expand the accordion */
  expanded: PropTypes.bool,
  /** Set to true, if this accordion is used in a navigational context and it shall be rendered as active */
  active: PropTypes.bool,
}

Accordion.defaultProps = {
  expanded: false,
  active: false,
}

export default Accordion;
