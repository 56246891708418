import React from 'react'

const Logo = ( props ) => (
  <svg width="239" height="40" viewBox="0 0 239 40" preserveAspectRatio="xMinYMin meet" {...props} fill={'currentColor'}>
    <g
      id="g43"
      transform="matrix(0.28499156,0,0,0.28499156,-6.516332,-6.5722131)">
      <path
        className="cls-1"
        d="M 107.63,127.86 C 92.7,134.16 74.5,130.41 63.47,116.6 52.62,103.03 52.63,83.37 63.47,69.8 74.5,56 92.7,52.25 107.63,58.54 c 8.42,3.55 18.13,1.85 24.6,-4.61 L 142.58,43.58 C 114.05,15.05 66.85,16.27 39.92,47.25 c -22.74,26.15 -22.74,65.75 0,91.9 26.93,30.98 74.13,32.2 102.66,3.67 l -10.35,-10.35 c -6.46,-6.46 -16.18,-8.17 -24.6,-4.61 z"
        id="path39"/>
      <path
        className="cls-1"
        d="m 81.33,93.07 c 0,6.41 5.22,11.63 11.63,11.63 6.41,0 11.63,-5.22 11.63,-11.63 0,-6.41 -5.21,-11.63 -11.63,-11.63 -6.42,0 -11.63,5.22 -11.63,11.63 z"
        id="path41"/>
    </g>
    <g
      id="g63"
      transform="matrix(0.28499156,0,0,0.28499156,-6.516332,-6.5722131)">
      <path
        className="cls-1"
        d="M 165.11,104.55 V 82.03 l 8.49,-8.49 h 31.17 v 7.94 h -28.22 l -3.23,3.23 v 17.15 l 3.23,3.23 h 28.22 v 7.94 H 173.6 l -8.49,-8.49 z"
        id="path45"/>
      <path
        className="cls-1"
        d="m 216.72,73.54 h 40.71 v 39.5 h -40.71 z m 32.49,31.56 V 81.49 h -24.27 v 23.61 z"
        id="path47"/>
      <path
        className="cls-1"
        d="m 272.49,73.54 h 32 l 8.05,8.38 v 16.55 h -10.9 l 14.68,14.57 H 305.91 L 291.45,98.63 H 280.6 v 14.41 h -8.11 z m 32.16,16.82 v -6.25 l -2.25,-2.36 h -21.81 v 8.6 h 24.05 z"
        id="path49"/>
      <path
        className="cls-1"
        d="m 327.72,73.54 h 39.12 v 8.11 h -30.63 v 7.78 h 23.39 v 7.73 h -23.39 v 7.67 h 30.63 v 8.22 h -39.12 v -39.5 z"
        id="path51"/>
      <path
        className="cls-1"
        d="m 381.09,73.54 h 44.6 l 8.22,8.44 v 31.06 h -7.89 V 84.77 l -3.23,-3.12 h -11.23 v 31.39 h -7.94 V 81.65 h -14.57 v 31.39 h -7.94 v -39.5 z"
        id="path53"/>
      <path
        className="cls-1"
        d="m 448.75,73.54 h 39.12 v 8.11 h -30.63 v 7.78 h 23.39 v 7.73 h -23.39 v 7.67 h 30.63 v 8.22 h -39.12 v -39.5 z"
        id="path55"/>
      <path
        className="cls-1"
        d="m 501.35,73.54 h 33.75 l 8.49,8.49 v 22.52 l -8.49,8.49 h -33.75 z m 30.79,31.56 3.23,-3.23 V 84.72 l -3.23,-3.23 h -22.57 v 23.61 z"
        id="path57"/>
      <path
        className="cls-1"
        d="m 557.07,73.54 h 8.22 v 39.5 h -8.22 z"
        id="path59"/>
      <path
        className="cls-1"
        d="m 580.35,73.54 h 32.05 l 8.49,8.49 v 31.01 h -7.94 V 97.86 h -24.66 v 15.18 h -7.94 z m 32.6,16.44 v -5.86 l -2.58,-2.63 h -22.08 v 8.49 z"
        id="path61"/>
    </g>
  </svg>
)

export default Logo
