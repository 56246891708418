import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useLocation } from '@gatsbyjs/reach-router'
import { useStaticQuery, graphql } from 'gatsby'

/*
<script type="application/ld+json">
{
"@context": "http://schema.org/",
"@type": "WebPage",
"name": "Getting Git Right",
"description":"Git is a free and open source version control system, originally created by Linus Torvalds in 2005. Unlike older centralized version control systems such as SVN and CVS, Git is distributed: every developer has the full history of their code repository locally. This makes the initial clone of the repository slower, but subsequent operations such as commit, blame, diff, merge, and log dramatically faster.",
"specialty":"Git"
}
</script>
 */

const ArticleJsonLd = ({ title, description, modifiedGmt, performTime, createdGmt }) => (
  <Helmet>
    <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "HowTo",
          "name": "${title}",
          "abstract": "${description}", ${createdGmt ? (`
          "dateCreated": "${createdGmt}"`) : ''}${modifiedGmt ? (`
          "dateModified": "${modifiedGmt}",`) : ''}${performTime ? (`
          "performTime": "${performTime}",`) : ''}
          "author": {
            "@type": "Organization",
            "url": "https://www.coremedia.com",
            "name": "CoreMedia",
            "contactPoint": {
              "@type": "ContactPoint",
              "email": "support@coremedia.com",
              "contactType": "Customer Support"
            }
          }
        }
      `}
    </script>
  </Helmet>
)

const Seo = ({ title, description, image, article, modifiedGmt, performTime, createdGmt }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    defaultImage,
    siteUrl,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <>
      <Helmet title={seo.title} titleTemplate={titleTemplate}>
        <meta name="description" content={seo.description}/>
        <meta name="image" content={seo.image}/>
        {seo.url && <meta property="og:url" content={seo.url}/>}
        {(article ? true : null) && <meta property="og:type" content="article"/>}
        {seo.title && <meta property="og:title" content={seo.title}/>}
        {seo.description && (
          <meta property="og:description" content={seo.description}/>
        )}
        {seo.image && <meta property="og:image" content={seo.image}/>}
      </Helmet>
      {article && <ArticleJsonLd
        title={seo.title}
        description={seo.description}
        modifiedGmt={modifiedGmt}
        performTime={performTime}
        createdGmt={createdGmt}
      />}
    </>
  )
}

export default Seo

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                titleTemplate
                defaultDescription: description
                siteUrl
                defaultImage: image
            }
        }
    }
`


Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}
