import React from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'

const COOKIE_NAME = 'crowd.token_key';
const URL = '/isInternal.json'
const LOGIN_URL = '/logMeIn.json'

const login = () => {
  return new Promise((resolve, reject) => {
    axios.get(LOGIN_URL)
      .then(() => {
        console.log('Login')
        resolve()
      })
      .catch(() => {
        console.log('Caught error')
        reject()
      })
  })
}

export default function useIsUserLoggedin() {

  const [isLoggedIn, set] = React.useState(false)
  const [checkedInternal, setCheckedInternal] = React.useState(false)

  const cookie = Cookies.get(COOKIE_NAME);

  // Check Cookies
  const checkCookie = React.useCallback(() => {
    if (cookie && !isLoggedIn) {
      set(true)
      return true
    }
    return false
  }, [cookie, isLoggedIn])

  const checkStatus = React.useCallback(() => {
    // Get not secure URL
    axios.get(URL).then(function (response) {
      if (response.status === 200 && !isLoggedIn) set(true)
    }).catch(function () {
      if (isLoggedIn) set(false)
    })
  }, [isLoggedIn])

  const triggerLogin = () => {
    login()
      .then(() => {
        if (!isLoggedIn) set(true);
      })
      .catch(() => {
        if (isLoggedIn) set(false)
        checkCookie()
      })
  }

  React.useEffect(() => {
    if (!checkCookie() && !isLoggedIn && !checkedInternal) {
      checkStatus()
      setCheckedInternal(true)
    }
  }, [checkCookie, isLoggedIn, checkedInternal, checkStatus, setCheckedInternal])

  return [isLoggedIn, triggerLogin]

}
