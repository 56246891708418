import React from 'react'
import styled from "styled-components";

const StyledFooter = styled.footer`
  background-color: #3f3f3f;
  min-height: 52px;
  height: 52px;
  margin-left: -15px;
  margin-right: -15px;
  padding: 12px 12px;
  padding-top: 17px;


  font-size: 12px;
  color: #fff;
  text-align: center;
  z-index: 100;
  position: relative;
`;

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <StyledFooter>
      Copyright © {currentYear} CoreMedia GmbH, CoreMedia Corporation. All Rights Reserved.
    </StyledFooter>
  )
}

export default Footer
